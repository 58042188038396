<template>
  <div class="sticky_mail__wrapper" :class="{'sticky_mail__wrapper--sent': sent}">
    <div v-if="!sent">
      <span class="sticky_mail__prompt">{{ getTextFromKey('no_time_to_wait') }}</span>
      <form class="sticky_mail__input__wrapper">
        <input v-model="email" type="email" class="sticky_mail__input" placeholder="Mail eintippen" @keydown.enter.prevent="insertEMail">
        <button class="sticky_mail__button" type="button" @click="insertEMail">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="var(--font-color)" d="M0.31,9.43a0.5,0.5,0,0,0,0,.93l8.3,3.23L23.15,0Z"/>
            <path fill="var(--font-color)" d="M9,14.6H9V21.4a0.5,0.5,0,0,0,.93.25L13.22,16l6,3.32A0.5,0.5,0,0,0,20,19l4-18.4Z"/>
          </svg>
        </button>
      </form>
    </div>
    <div v-else>
      <span class="sticky_mail__prompt">{{ getTextFromKey('thank_you') }}</span>
    </div>
    <div class="sticky_mail__close" @click="$emit('close')">
      <svg class="sticky_mail__close__icon" viewBox="0 0 32 32">
        <path fill="var(--font-color)" d="M2.624 8.297l2.963-2.963 23.704 23.704-2.963 2.963-23.704-23.704z"/>
        <path fill="var(--font-color)" d="M2.624 29.037l23.704-23.704 2.963 2.963-23.704 23.704-2.963-2.963z"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StickyMail',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    sourceid: {
      type: [String],
      default: ''
    },
    integration: {
      type: [String],
      default: ''
    },
    launch: {
      type: Object
    },
    socket: {
      type: Object
    },
  },
  data() {
    return {
      // data property for v-model binding with real textarea tag
      val: null,
      height: 25,
      maxHeight: 60,
      email: '',
      sent: false
    }
  },
  mounted() {
    const localSent = localStorage.getItem('destibotMailSent')
    if (localSent) {

      const date = new Date(localSent)
      const now = Date.now()
      // check if date is older than 1 day
      if (date.getTime() + 86400000 < now) {
        // remove localstorage
      } else {
        this.sent = true
      }
    }
  },
  methods: {
    getTextFromKey(key) {
      const textObj = this.launch.texts.find(itm => itm.key === key)
      return textObj[this.launch.language] || textObj['de']
    },
    async insertEMail() {
      await fetch(process.env.VUE_APP_API_URL + `/profile/${this.sourceid}/update-mail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "U82idgUDgg7gGR3y0bXkx4BAFzmitneA882TUuxn"
        },
        body: JSON.stringify({
          "email": this.email,
          "integration": this.integration
        })
      })
        .then(()=> {
          this.sent = true
          this.socket.emit('lead', { integration: this.integration }, this.sourceid);
          localStorage.setItem('destibotMailSent', new Date())
        })
    }
  }
}
</script>

<style scoped lang="scss">

$primary-color: var(--primary);
$secondary-color: var(--secondary);
$font-color: var(--font-color);

.sticky_mail__wrapper {
  position: relative;
  bottom: 0;
  background-color: $secondary-color;
  color:$font-color;
  padding: 12px;
  border: none;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  &.sticky_mail__wrapper--sent {
    background-color: #4BB543;
  }
}

.sticky_mail__prompt {
  display: block;
  width: 80%;
  margin: 0 auto;
  line-height: 1.4;
}

.sticky_mail__input {
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  color: $font-color;
  background-color: $secondary-color;
  text-align: center;
  &:focus {
    outline: black 0.5px solid;
  }
}

.sticky_mail__input__wrapper {
  display: grid;
  grid-template-columns: auto 50px;
  gap: 10px;
  padding: 10px;
  width: 100%;
}

.sticky_mail__button {
  background-color: transparent;
  border: none;
  padding-top: 4px;
  cursor: pointer;
}

.sticky_mail__close {
  width: 20px;
  padding: 8px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

.sticky_mail__close__icon {
  width: 100%;
  height: auto;
}

</style>
