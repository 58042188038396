<template>
  <div class="destibot__root" ref="destibot">
    <div class="destibot destibot__type--bar" :class="{'destibot--closed':closed}">
      <div class="destibot__header" @click="toggleChat">
        <p><span v-if="launch.config.newChip" class="u-text-highlight">{{ getTextFromKey('chip') }}</span> <strong>{{ getTextFromKey('hast_du_eine_frage') }}</strong>
        </p>

        <div class="destibot__logos">
          <svg v-if="launch.config.whatsapp !== false" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
                :fill="launch.frontend.font"
                d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/>
          </svg>
          <svg class="destibot__close-button icon-speech" viewBox="0 0 32 32">
            <path
                :fill="launch.frontend.font"
                d="M21.795 5.333h-11.413c-0.038 0-0.077 0-0.114 0l-0.134 0.011v2.796c0.143-0.006 0.273-0.009 0.385-0.009h11.277c0.070 0 7.074 0.213 7.074 7.695 0 5.179-2.956 7.695-9.036 7.695h-3.792c-0.691 0-1.12 0.526-1.38 1.159l-1.387 3.093-1.625 3.77 0.245 0.453h2.56l2.538-5.678h2.837c7.633 0 11.84-3.727 11.84-10.494 0.001-8.564-7.313-10.492-9.875-10.492z"/>
            <path
                :fill="launch.frontend.font"
                d="M10.912 24.259c-0.242-0.442-0.703-0.737-1.234-0.737-0 0-0 0-0 0h-0.56c-0.599-0.011-1.171-0.108-1.71-0.28l0.042 0.012c-1.82-0.559-4.427-2.26-4.427-7.424 0-6.683 5.024-7.597 7.109-7.686v-2.8c-2.042 0.095-9.91 1.067-9.91 10.483 0 4.832 1.961 7.367 3.606 8.64 1.38 1.067 3.109 1.744 4.991 1.843l0.033 0.019 2.805 5.211 1.41-3.273z"/>
          </svg>
          <svg class="destibot__close-button icon-close" viewBox="0 0 32 32">
            <path
                :fill="launch.frontend.font"
                d="M2.624 8.297l2.963-2.963 23.704 23.704-2.963 2.963-23.704-23.704z"/>
            <path
                :fill="launch.frontend.font"
                d="M2.624 29.037l23.704-23.704 2.963 2.963-23.704 23.704-2.963-2.963z"/>
          </svg>
        </div>
      </div>
      <div v-if="launch.config.brand" class="destibot__logo" :class="{'destibot__logo--closed': !closed}">
        <a :href="launch.config.destibotlink" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
               viewBox="0 0 465.21 116.62">
            <defs>
              <clipPath id="a" transform="translate(0 0)">
                <rect class="a" width="563.3" height="150"/>
              </clipPath>
            </defs>
            <g class="b">
              <path class="c"
                    d="M166.26,3.19a4.37,4.37,0,0,1,1.26,3.2L168,46a24.2,24.2,0,0,1-3,12.34,23.45,23.45,0,0,1-20.57,12.13,24.1,24.1,0,0,1-12.25-3,22.78,22.78,0,0,1-8.76-8.58,24.54,24.54,0,0,1-3.29-12.25,26,26,0,0,1,2.75-12.34,22.06,22.06,0,0,1,7.89-8.75,21.16,21.16,0,0,1,11.3-3.27A21.33,21.33,0,0,1,159,30l-.3-23.46a4.27,4.27,0,0,1,1.22-3.23,4.81,4.81,0,0,1,6.39-.09M152.17,60.43a14.86,14.86,0,0,0,5.45-5.93,17.42,17.42,0,0,0,1.89-8.37,17.07,17.07,0,0,0-2.11-8.27,15.35,15.35,0,0,0-21.32-5.55,15.34,15.34,0,0,0-5.49,5.89,16.87,16.87,0,0,0-1.93,8.33,17,17,0,0,0,2.15,8.31,15.19,15.19,0,0,0,5.64,5.8,15.6,15.6,0,0,0,15.72-.21"
                    transform="translate(0 0)"/>
              <path class="c"
                    d="M221.61,48.37a4,4,0,0,1-2.87,1.09h-33a15.29,15.29,0,0,0,5.51,9.82A17.08,17.08,0,0,0,202.4,63a16.9,16.9,0,0,0,5.44-.95,16.09,16.09,0,0,0,4.64-2.35,4.51,4.51,0,0,1,2.87-1,3.74,3.74,0,0,1,2.61.87,3.79,3.79,0,0,1,1.56,2.95,3.15,3.15,0,0,1-1.39,2.61A24.06,24.06,0,0,1,210.79,69a26.5,26.5,0,0,1-21.16-1.61,22.38,22.38,0,0,1-8.83-8.56,24.36,24.36,0,0,1-3.17-12.42,25.74,25.74,0,0,1,3-12.48,21.66,21.66,0,0,1,8.35-8.6,23.75,23.75,0,0,1,12.12-3.08,21.86,21.86,0,0,1,11.56,2.95,19.49,19.49,0,0,1,7.47,8.26,27.18,27.18,0,0,1,2.61,12.16,3.63,3.63,0,0,1-1.13,2.74M190.8,33.21a15.57,15.57,0,0,0-5,9.29h28.94a15.38,15.38,0,0,0-4.34-9.29,12.76,12.76,0,0,0-9.3-3.48,15,15,0,0,0-10.3,3.48"
                    transform="translate(0 0)"/>
              <path class="c"
                    d="M236.73,68.67a21.3,21.3,0,0,1-8.17-4.79,3.68,3.68,0,0,1-1.13-2.69,4.05,4.05,0,0,1,1.74-3.39,5.27,5.27,0,0,1,3-1.22,4.66,4.66,0,0,1,3.31,1.57,13.43,13.43,0,0,0,4.95,3.17,18.42,18.42,0,0,0,7,1.35A14.94,14.94,0,0,0,255.2,61a5,5,0,0,0,2.74-4.44,5.24,5.24,0,0,0-2.7-4.47c-1.79-1.19-4.84-2.19-9.12-3q-16.69-3.21-16.69-13.73A10.75,10.75,0,0,1,232,28.16a15.34,15.34,0,0,1,6.61-4.43,25.46,25.46,0,0,1,8.69-1.47,26.53,26.53,0,0,1,10.12,1.82,17.36,17.36,0,0,1,7.09,5,4.45,4.45,0,0,1,1.13,2.78,3.52,3.52,0,0,1-1.48,2.7,4.07,4.07,0,0,1-2.44.69,6,6,0,0,1-4.08-1.65A13.21,13.21,0,0,0,253,30.81a18.52,18.52,0,0,0-5.91-.82,14,14,0,0,0-6.56,1.35A4.15,4.15,0,0,0,238,35.12a4.74,4.74,0,0,0,.87,2.91,7.78,7.78,0,0,0,3.3,2.17,41.82,41.82,0,0,0,7,2q9.47,1.91,13.43,5.3a11,11,0,0,1,4,8.78,12.77,12.77,0,0,1-2.17,7.25A14.4,14.4,0,0,1,258,68.62a24.78,24.78,0,0,1-10.12,1.87,33.61,33.61,0,0,1-11.13-1.82"
                    transform="translate(0 0)"/>
              <path class="c"
                    d="M297.18,62.67a4.53,4.53,0,0,1,1.08,3.13,3.91,3.91,0,0,1-1.43,3.13,5.52,5.52,0,0,1-3.69,1.21h-1.57a14.38,14.38,0,0,1-7.78-2.21,15.46,15.46,0,0,1-5.51-6,18.22,18.22,0,0,1-2-8.61V31.82h-4a4.17,4.17,0,0,1-3-1.05,3.4,3.4,0,0,1-1.13-2.61,3.63,3.63,0,0,1,1.13-2.78,4.21,4.21,0,0,1,3-1h4V11.83a4.35,4.35,0,1,1,8.69,0V24.34h7.39a4.2,4.2,0,0,1,2.95,1,3.63,3.63,0,0,1,1.13,2.78,3.4,3.4,0,0,1-1.13,2.61,4.16,4.16,0,0,1-2.95,1.05H285V53.28a8.87,8.87,0,0,0,1.91,5.82,5.82,5.82,0,0,0,4.69,2.35h3a3.44,3.44,0,0,1,2.65,1.22"
                    transform="translate(0 0)"/>
              <path class="c"
                    d="M307.05,13.23a5.33,5.33,0,0,1-1.64-3.9,5.33,5.33,0,0,1,1.64-3.9,5.46,5.46,0,0,1,7.8,0,5.33,5.33,0,0,1,1.64,3.9,5.33,5.33,0,0,1-1.64,3.9,5.46,5.46,0,0,1-7.8,0m.73,55.65a4.3,4.3,0,0,1-1.26-3.17V27a4.26,4.26,0,0,1,1.26-3.21,4.79,4.79,0,0,1,6.39,0A4.34,4.34,0,0,1,315.38,27V65.71a4.39,4.39,0,0,1-1.21,3.17,4.68,4.68,0,0,1-6.39,0"
                    transform="translate(0 0)"/>
              <path class="d"
                    d="M365.4,25.38a22.05,22.05,0,0,1,8,8.65,26,26,0,0,1,2.91,12.3,24.56,24.56,0,0,1-3.13,12.3,22.92,22.92,0,0,1-8.65,8.69,24.11,24.11,0,0,1-12.21,3.17,23.63,23.63,0,0,1-12.12-3.17,23.31,23.31,0,0,1-8.61-8.69,24.29,24.29,0,0,1-3.17-12.3V6.7a4.26,4.26,0,0,1,1.26-3.22,4.82,4.82,0,0,1,6.39,0,4.35,4.35,0,0,1,1.22,3.22V30.16a21.17,21.17,0,0,1,7.38-5.82,22.12,22.12,0,0,1,20.73,1m-5.21,35.16a15.33,15.33,0,0,0,5.56-5.87,17.11,17.11,0,0,0,2-8.34,16.87,16.87,0,0,0-2-8.3,15.33,15.33,0,0,0-5.56-5.82A15.17,15.17,0,0,0,338.94,38a17.07,17.07,0,0,0-2,8.3,17.31,17.31,0,0,0,2,8.34,15.32,15.32,0,0,0,21.25,5.87"
                    transform="translate(0 0)"/>
              <path class="d"
                    d="M395.16,67.41a21.78,21.78,0,0,1-8.51-8.57,25.12,25.12,0,0,1-3-12.42,25.32,25.32,0,0,1,3-12.52,21.76,21.76,0,0,1,8.51-8.56,24.88,24.88,0,0,1,12.43-3.08,24.42,24.42,0,0,1,12.3,3.08,22.25,22.25,0,0,1,8.52,8.56,25.12,25.12,0,0,1,3.08,12.52,25.11,25.11,0,0,1-3,12.42A21.94,21.94,0,0,1,420,67.41a24.73,24.73,0,0,1-12.39,3.08,25,25,0,0,1-12.43-3.08m20.39-6.83A14.37,14.37,0,0,0,421,54.8a17.68,17.68,0,0,0,2-8.38A18,18,0,0,0,421,38a14.42,14.42,0,0,0-5.47-5.82,16.25,16.25,0,0,0-15.91,0A14.67,14.67,0,0,0,394.12,38a17.68,17.68,0,0,0-2,8.44,17.36,17.36,0,0,0,2,8.38,14.63,14.63,0,0,0,5.52,5.78,16.18,16.18,0,0,0,15.91,0"
                    transform="translate(0 0)"/>
              <path class="d"
                    d="M464.13,62.67a4.53,4.53,0,0,1,1.08,3.13,3.91,3.91,0,0,1-1.43,3.13,5.52,5.52,0,0,1-3.69,1.21h-1.57a14.38,14.38,0,0,1-7.78-2.21,15.56,15.56,0,0,1-5.52-6,18.34,18.34,0,0,1-2-8.61V31.82h-4a4.17,4.17,0,0,1-3-1.05,3.4,3.4,0,0,1-1.13-2.61,3.63,3.63,0,0,1,1.13-2.78,4.21,4.21,0,0,1,3-1h4V11.83a4.35,4.35,0,1,1,8.69,0V24.34h7.39a4.23,4.23,0,0,1,3,1,3.63,3.63,0,0,1,1.13,2.78,3.4,3.4,0,0,1-1.13,2.61,4.19,4.19,0,0,1-3,1.05h-7.39V53.28a8.87,8.87,0,0,0,1.92,5.82,5.82,5.82,0,0,0,4.69,2.35h3a3.45,3.45,0,0,1,2.66,1.22"
                    transform="translate(0 0)"/>
              <path class="c"
                    d="M47.91,0C19.7,0,0,19.3,0,46.93,0,57.29,5.65,70.18,16.8,85.25a245.2,245.2,0,0,0,26.1,29.34,7.2,7.2,0,0,0,10,0A245.59,245.59,0,0,0,76.61,88.5a2.4,2.4,0,0,0-.2-3.16L65.84,74.77a2.42,2.42,0,0,0-1.7-.71,2.44,2.44,0,0,0-1.14.29c-.25.13-.49.27-.74.39a31.54,31.54,0,0,1-14.35,3.43,31.88,31.88,0,1,1,31.8-33.86c.05.73.07,32.16.07,32.16a2.41,2.41,0,0,0,1.73,2.3,2.22,2.22,0,0,0,.67.1,2.42,2.42,0,0,0,2-1.11C94.31,62,95.82,51.66,95.82,46.93,95.82,19.3,76.12,0,47.91,0"
                    transform="translate(0 0)"/>
              <path class="d"
                    d="M72.05,44.9A24.19,24.19,0,1,0,47.91,70.6,23.79,23.79,0,0,0,58.79,68a23,23,0,0,0,2.7-1.58,2.57,2.57,0,0,1,3.22.31l5.34,5.34a1.2,1.2,0,0,0,2-.85V46.41c0-.51,0-1,0-1.51M38.81,50.78a4.38,4.38,0,1,1,4.38-4.37,4.37,4.37,0,0,1-4.38,4.37m17.5,0a4.38,4.38,0,1,1,4.37-4.37,4.38,4.38,0,0,1-4.37,4.37"
                    transform="translate(0 0)"/>
            </g>
          </svg>
        </a>
      </div>
      <div class="destibot__message-window" ref="chatwindow">
        <ul class="destibot__messages" ref="messages">

          <div class="destibot__dataprotection"><a
              href="https://destibot.ai/datenschutz-bot?utm_campaign=destibot-integration-dataprotection&utm_source=webclient&utm_medium=" target="_blank">{{ getTextFromKey('data_protection') }}</a>
          </div>

          <li v-if="launch.config.brand" class="is-destilogo">
            <a :href="launch.config.destibotlink" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                   viewBox="0 0 465.21 116.62">
                <defs>
                  <clipPath id="a" transform="translate(0 0)">
                    <rect class="a" width="563.3" height="150"/>
                  </clipPath>
                </defs>
                <g class="b">
                  <path class="c"
                        d="M166.26,3.19a4.37,4.37,0,0,1,1.26,3.2L168,46a24.2,24.2,0,0,1-3,12.34,23.45,23.45,0,0,1-20.57,12.13,24.1,24.1,0,0,1-12.25-3,22.78,22.78,0,0,1-8.76-8.58,24.54,24.54,0,0,1-3.29-12.25,26,26,0,0,1,2.75-12.34,22.06,22.06,0,0,1,7.89-8.75,21.16,21.16,0,0,1,11.3-3.27A21.33,21.33,0,0,1,159,30l-.3-23.46a4.27,4.27,0,0,1,1.22-3.23,4.81,4.81,0,0,1,6.39-.09M152.17,60.43a14.86,14.86,0,0,0,5.45-5.93,17.42,17.42,0,0,0,1.89-8.37,17.07,17.07,0,0,0-2.11-8.27,15.35,15.35,0,0,0-21.32-5.55,15.34,15.34,0,0,0-5.49,5.89,16.87,16.87,0,0,0-1.93,8.33,17,17,0,0,0,2.15,8.31,15.19,15.19,0,0,0,5.64,5.8,15.6,15.6,0,0,0,15.72-.21"
                        transform="translate(0 0)"/>
                  <path class="c"
                        d="M221.61,48.37a4,4,0,0,1-2.87,1.09h-33a15.29,15.29,0,0,0,5.51,9.82A17.08,17.08,0,0,0,202.4,63a16.9,16.9,0,0,0,5.44-.95,16.09,16.09,0,0,0,4.64-2.35,4.51,4.51,0,0,1,2.87-1,3.74,3.74,0,0,1,2.61.87,3.79,3.79,0,0,1,1.56,2.95,3.15,3.15,0,0,1-1.39,2.61A24.06,24.06,0,0,1,210.79,69a26.5,26.5,0,0,1-21.16-1.61,22.38,22.38,0,0,1-8.83-8.56,24.36,24.36,0,0,1-3.17-12.42,25.74,25.74,0,0,1,3-12.48,21.66,21.66,0,0,1,8.35-8.6,23.75,23.75,0,0,1,12.12-3.08,21.86,21.86,0,0,1,11.56,2.95,19.49,19.49,0,0,1,7.47,8.26,27.18,27.18,0,0,1,2.61,12.16,3.63,3.63,0,0,1-1.13,2.74M190.8,33.21a15.57,15.57,0,0,0-5,9.29h28.94a15.38,15.38,0,0,0-4.34-9.29,12.76,12.76,0,0,0-9.3-3.48,15,15,0,0,0-10.3,3.48"
                        transform="translate(0 0)"/>
                  <path class="c"
                        d="M236.73,68.67a21.3,21.3,0,0,1-8.17-4.79,3.68,3.68,0,0,1-1.13-2.69,4.05,4.05,0,0,1,1.74-3.39,5.27,5.27,0,0,1,3-1.22,4.66,4.66,0,0,1,3.31,1.57,13.43,13.43,0,0,0,4.95,3.17,18.42,18.42,0,0,0,7,1.35A14.94,14.94,0,0,0,255.2,61a5,5,0,0,0,2.74-4.44,5.24,5.24,0,0,0-2.7-4.47c-1.79-1.19-4.84-2.19-9.12-3q-16.69-3.21-16.69-13.73A10.75,10.75,0,0,1,232,28.16a15.34,15.34,0,0,1,6.61-4.43,25.46,25.46,0,0,1,8.69-1.47,26.53,26.53,0,0,1,10.12,1.82,17.36,17.36,0,0,1,7.09,5,4.45,4.45,0,0,1,1.13,2.78,3.52,3.52,0,0,1-1.48,2.7,4.07,4.07,0,0,1-2.44.69,6,6,0,0,1-4.08-1.65A13.21,13.21,0,0,0,253,30.81a18.52,18.52,0,0,0-5.91-.82,14,14,0,0,0-6.56,1.35A4.15,4.15,0,0,0,238,35.12a4.74,4.74,0,0,0,.87,2.91,7.78,7.78,0,0,0,3.3,2.17,41.82,41.82,0,0,0,7,2q9.47,1.91,13.43,5.3a11,11,0,0,1,4,8.78,12.77,12.77,0,0,1-2.17,7.25A14.4,14.4,0,0,1,258,68.62a24.78,24.78,0,0,1-10.12,1.87,33.61,33.61,0,0,1-11.13-1.82"
                        transform="translate(0 0)"/>
                  <path class="c"
                        d="M297.18,62.67a4.53,4.53,0,0,1,1.08,3.13,3.91,3.91,0,0,1-1.43,3.13,5.52,5.52,0,0,1-3.69,1.21h-1.57a14.38,14.38,0,0,1-7.78-2.21,15.46,15.46,0,0,1-5.51-6,18.22,18.22,0,0,1-2-8.61V31.82h-4a4.17,4.17,0,0,1-3-1.05,3.4,3.4,0,0,1-1.13-2.61,3.63,3.63,0,0,1,1.13-2.78,4.21,4.21,0,0,1,3-1h4V11.83a4.35,4.35,0,1,1,8.69,0V24.34h7.39a4.2,4.2,0,0,1,2.95,1,3.63,3.63,0,0,1,1.13,2.78,3.4,3.4,0,0,1-1.13,2.61,4.16,4.16,0,0,1-2.95,1.05H285V53.28a8.87,8.87,0,0,0,1.91,5.82,5.82,5.82,0,0,0,4.69,2.35h3a3.44,3.44,0,0,1,2.65,1.22"
                        transform="translate(0 0)"/>
                  <path class="c"
                        d="M307.05,13.23a5.33,5.33,0,0,1-1.64-3.9,5.33,5.33,0,0,1,1.64-3.9,5.46,5.46,0,0,1,7.8,0,5.33,5.33,0,0,1,1.64,3.9,5.33,5.33,0,0,1-1.64,3.9,5.46,5.46,0,0,1-7.8,0m.73,55.65a4.3,4.3,0,0,1-1.26-3.17V27a4.26,4.26,0,0,1,1.26-3.21,4.79,4.79,0,0,1,6.39,0A4.34,4.34,0,0,1,315.38,27V65.71a4.39,4.39,0,0,1-1.21,3.17,4.68,4.68,0,0,1-6.39,0"
                        transform="translate(0 0)"/>
                  <path class="d"
                        d="M365.4,25.38a22.05,22.05,0,0,1,8,8.65,26,26,0,0,1,2.91,12.3,24.56,24.56,0,0,1-3.13,12.3,22.92,22.92,0,0,1-8.65,8.69,24.11,24.11,0,0,1-12.21,3.17,23.63,23.63,0,0,1-12.12-3.17,23.31,23.31,0,0,1-8.61-8.69,24.29,24.29,0,0,1-3.17-12.3V6.7a4.26,4.26,0,0,1,1.26-3.22,4.82,4.82,0,0,1,6.39,0,4.35,4.35,0,0,1,1.22,3.22V30.16a21.17,21.17,0,0,1,7.38-5.82,22.12,22.12,0,0,1,20.73,1m-5.21,35.16a15.33,15.33,0,0,0,5.56-5.87,17.11,17.11,0,0,0,2-8.34,16.87,16.87,0,0,0-2-8.3,15.33,15.33,0,0,0-5.56-5.82A15.17,15.17,0,0,0,338.94,38a17.07,17.07,0,0,0-2,8.3,17.31,17.31,0,0,0,2,8.34,15.32,15.32,0,0,0,21.25,5.87"
                        transform="translate(0 0)"/>
                  <path class="d"
                        d="M395.16,67.41a21.78,21.78,0,0,1-8.51-8.57,25.12,25.12,0,0,1-3-12.42,25.32,25.32,0,0,1,3-12.52,21.76,21.76,0,0,1,8.51-8.56,24.88,24.88,0,0,1,12.43-3.08,24.42,24.42,0,0,1,12.3,3.08,22.25,22.25,0,0,1,8.52,8.56,25.12,25.12,0,0,1,3.08,12.52,25.11,25.11,0,0,1-3,12.42A21.94,21.94,0,0,1,420,67.41a24.73,24.73,0,0,1-12.39,3.08,25,25,0,0,1-12.43-3.08m20.39-6.83A14.37,14.37,0,0,0,421,54.8a17.68,17.68,0,0,0,2-8.38A18,18,0,0,0,421,38a14.42,14.42,0,0,0-5.47-5.82,16.25,16.25,0,0,0-15.91,0A14.67,14.67,0,0,0,394.12,38a17.68,17.68,0,0,0-2,8.44,17.36,17.36,0,0,0,2,8.38,14.63,14.63,0,0,0,5.52,5.78,16.18,16.18,0,0,0,15.91,0"
                        transform="translate(0 0)"/>
                  <path class="d"
                        d="M464.13,62.67a4.53,4.53,0,0,1,1.08,3.13,3.91,3.91,0,0,1-1.43,3.13,5.52,5.52,0,0,1-3.69,1.21h-1.57a14.38,14.38,0,0,1-7.78-2.21,15.56,15.56,0,0,1-5.52-6,18.34,18.34,0,0,1-2-8.61V31.82h-4a4.17,4.17,0,0,1-3-1.05,3.4,3.4,0,0,1-1.13-2.61,3.63,3.63,0,0,1,1.13-2.78,4.21,4.21,0,0,1,3-1h4V11.83a4.35,4.35,0,1,1,8.69,0V24.34h7.39a4.23,4.23,0,0,1,3,1,3.63,3.63,0,0,1,1.13,2.78,3.4,3.4,0,0,1-1.13,2.61,4.19,4.19,0,0,1-3,1.05h-7.39V53.28a8.87,8.87,0,0,0,1.92,5.82,5.82,5.82,0,0,0,4.69,2.35h3a3.45,3.45,0,0,1,2.66,1.22"
                        transform="translate(0 0)"/>
                  <path class="c"
                        d="M47.91,0C19.7,0,0,19.3,0,46.93,0,57.29,5.65,70.18,16.8,85.25a245.2,245.2,0,0,0,26.1,29.34,7.2,7.2,0,0,0,10,0A245.59,245.59,0,0,0,76.61,88.5a2.4,2.4,0,0,0-.2-3.16L65.84,74.77a2.42,2.42,0,0,0-1.7-.71,2.44,2.44,0,0,0-1.14.29c-.25.13-.49.27-.74.39a31.54,31.54,0,0,1-14.35,3.43,31.88,31.88,0,1,1,31.8-33.86c.05.73.07,32.16.07,32.16a2.41,2.41,0,0,0,1.73,2.3,2.22,2.22,0,0,0,.67.1,2.42,2.42,0,0,0,2-1.11C94.31,62,95.82,51.66,95.82,46.93,95.82,19.3,76.12,0,47.91,0"
                        transform="translate(0 0)"/>
                  <path class="d"
                        d="M72.05,44.9A24.19,24.19,0,1,0,47.91,70.6,23.79,23.79,0,0,0,58.79,68a23,23,0,0,0,2.7-1.58,2.57,2.57,0,0,1,3.22.31l5.34,5.34a1.2,1.2,0,0,0,2-.85V46.41c0-.51,0-1,0-1.51M38.81,50.78a4.38,4.38,0,1,1,4.38-4.37,4.37,4.37,0,0,1-4.38,4.37m17.5,0a4.38,4.38,0,1,1,4.37-4.37,4.38,4.38,0,0,1-4.37,4.37"
                        transform="translate(0 0)"/>
                </g>
              </svg>
            </a>
          </li>

          <template v-for="(message, ind) in messages">

            <li v-if="message.class === 'is-ai'" :key="ind" class="is-ai animation">
              <div class="is-ai__profile-picture">
                <img :src="launch.config.icon_image" style="width: 40px; height: 100%; transform: scale(0.7); display: block">
              </div>
              <span class="destibot__arrow destibot__arrow--left"></span>
              <p class='destibot__message' v-html="markdownToHtmlLink(message.text)"></p>
            </li>

            <li v-if="message.class === 'is-toast'" :key="ind" class="is-toast animation">
              <p class='destibot__toast'>{{ message.text }}</p>
            </li>

            <li v-if="message.class === 'is-error'" :key="ind" class="is-error animation">
              <p class='destibot__error'>{{ message.text }}</p>
              <button class="destibot__button" @click.prevent="retry">{{ getTextFromKey('try_again') }}</button>
            </li>


            <li v-if="message.class === 'is-user'" :key="ind" class='is-user animation' :class="{'hide': message.ignored }">
              <p class='destibot__message'>
                {{ message.text }}
              </p>
              <span class='destibot__arrow destibot__arrow--right'></span>
            </li>

          </template>


          <li v-if="loadingAnswer" class='is-ai animation'>
            <div class="is-ai__profile-picture">
              <img :src="launch.config.icon_image" style="width: 40px; height: 100%; transform: scale(0.7); display: block">
            </div>
            <span class='destibot__arrow destibot__arrow--left'></span>
            <div class='destibot__message'>
              <span class='loader'><span class='loader__dot'></span><span class='loader__dot'></span><span
                  class='loader__dot'></span></span>
            </div>
          </li>

          <div v-if="suggestions.length >= 1" class="destibot__suggestions__wrapper">
            <div class="destibot__suggestion__title" v-if="messages.length <= 1">
              {{ getTextFromKey('suggestions') }}
            </div>
            <div class="destibot__suggestions">
              <div v-for="(suggestion, ind) in suggestions" :key="ind" class="destibot__suggestion" @click="userUsesSuggestion(suggestion)">
                {{ suggestion.label }}
              </div>
            </div>
          </div>
        </ul>
      </div>
      <div class="destibot__footer">
        <div v-if="!closed && hasConsent" class="destibot__contact__wrapper">
          <sticky-mail v-if="enterEmail" @close="enterEmail = false" :sourceid="sourceid" :integration="integration" :launch="launch" :socket="socket" />
          <div v-else-if="launch.config.contact" class="destibot__contact" @click="enterEmail = true">
            <span><u>{{ getTextFromKey('contact_prompt') }}</u></span>
          </div>
        </div>
        <div class="destibot__entry destibot--closed">
          <TextareaAutosize
            v-model="userWriting"
            :disabled="loadingAnswer || !hasConsent"
            class="destibot__input"
            :placeholder="loadingAnswer ? getTextFromKey('wait') : getTextFromKey('write')"
            @enter="userSentMessage"
            :focus="focus"
          ></TextareaAutosize>
          <!--<input type="text" v-model="userWriting" ref="messageenter" :disabled="loadingAnswer || !hasConsent" class="destibot__input"
                 :placeholder="loadingAnswer ? 'warten...' : 'Schreibe eine Nachricht...'"
                 @keydown.enter.prevent="userSentMessage"/>ä--->

          <div class="destibot__submit" :class="{'destibot__submit--wa':launch.config.whatsapp !== false}" @click="userSentMessage">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M0.31,9.43a0.5,0.5,0,0,0,0,.93l8.3,3.23L23.15,0Z"/>
              <path d="M9,14.6H9V21.4a0.5,0.5,0,0,0,.93.25L13.22,16l6,3.32A0.5,0.5,0,0,0,20,19l4-18.4Z"/>
            </svg>
          </div>
          <a v-if="launch.config.whatsapp !== false" :href="launch.config.whatsapp" target="_blank" class="destibot__wa-action">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                  fill="white"
                  d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/>
            </svg>
          </a>
        </div>
      </div>
      <div v-if="!closed & !hasConsent" class="destibot__consent">
        <div>
          Ich akzeptiere die <a href="https://destibot.ai/datenschutz-bot?utm_campaign=destibot-integration-dataprotection&utm_source=webclient&utm_medium=" target="_blank">{{ getTextFromKey('data_protection') }}</a>. <br>
          {{ getTextFromKey('consent') }}
          <br>
          <br>
          <button @click="consentGiven">{{ getTextFromKey('consent_button') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import {createClient} from '@supabase/supabase-js'
import TextareaAutosize from "@/components/TextareaAutosize.vue";
import StickyMail from "@/components/StickyMail.vue";
import {io} from "socket.io-client";
export default {
  name: 'DestiBot',
  components: {StickyMail, TextareaAutosize},
  props: {
    sourceid: String,
    integration: String,
    launch: Object,
    consent: Boolean
  },
  data() {
    return {
      messages: [],
      closed: true,
      loadingAnswer: false,
      userWriting: '',
      latestWriting: '',
      supabase: null,
      loadingTimer: null,
      errorTimer: null,
      hasConsent: false,
      focus: 0,
      enterEmail: false,
      suggestion: null,
      suggestions: [],
      socket: null
    }
  },
  async mounted() {
    // this.supabase = createClient('https://wdmffaamldunlqudzjcd.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndkbWZmYWFtbGR1bmxxdWR6amNkIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Nzk4NTg4NTIsImV4cCI6MTk5NTQzNDg1Mn0.CsBgFwo0bzc56x_wPY8cQNVxkZjSAgOJjy7qN8tZ2Zw')

    this.$refs.destibot.style.setProperty('--primary', this.launch.frontend.primary)
    this.$refs.destibot.style.setProperty('--secondary', this.launch.frontend.secondary)
    this.$refs.destibot.style.setProperty('--font-color', this.launch.frontend.font)

    this.messages.push({class: 'is-ai', text: this.launch.welcome_message});
    this.suggestions = this.launch.suggestions || []

    // TODO Check consent date
    if (localStorage.getItem('destibotConsentDate') && this.isDateYoungerThanSixMonths(localStorage.getItem('destibotConsentDate'))) {
      this.hasConsent = true
    }

    if (this.launch.chats.length !== 0) {
      this.launch.chats.forEach(itm => {
        this.messages.push({class: itm.sender, text: itm.message, ignored: itm.ignored});
      })
    }

    if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local') {
      await this.toggleChat();
    }
  },
  methods: {
    isDateYoungerThanSixMonths(date) {
      const sixMonthsAgo = new Date()
      sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6)
      return new Date(date) > sixMonthsAgo
    },
    getTextFromKey(key) {
      const textObj = this.launch.texts.find(itm => itm.key === key)
      return textObj[this.launch.language] || textObj['de']
    },
    async toggleChat() {
      this.closed = !this.closed
      if (this.closed === false) {
        // window is opened

        this.socket = io(process.env.VUE_APP_WS_URL);
        this.socket.emit('join', {
          "source_key": this.integration || "web-integration-fallback-55bfef95601",
          "source_id": this.sourceid,
          "url": location.href,
          "lang": "de"
        });

        this.socket.on('chat message', (msg) => {
          console.log('received: ' + msg);
          if (msg) {
            this.messages.push({class: 'is-ai', text: msg})
            this.scrollChatWindowToBottom()
            clearTimeout(this.loadingTimer)
            clearTimeout(this.errorTimer)
            this.loadingAnswer = false
            this.$nextTick(() => {
              this.focus++;
            })
          }
        });

        this.socket.on('email popup', (popup) => {
          console.log('email popup: ' + popup);
          if (popup) {
            this.enterEmail = true
          }
        });

        this.socket.on('suggestions', (msg) => {
          console.log('suggestion received: ' + msg);
          if (msg) {
            this.suggestions = []
            const sugg = JSON.parse(msg)
            sugg.forEach(itm => {
              this.suggestions.push({
                label: itm,
                text: itm,
                id: "suggestion-" + Math.random().toString(36).substring(7)
              })
            })
          }
        });

        //this.supabase = createClient('https://wdmffaamldunlqudzjcd.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndkbWZmYWFtbGR1bmxxdWR6amNkIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Nzk4NTg4NTIsImV4cCI6MTk5NTQzNDg1Mn0.CsBgFwo0bzc56x_wPY8cQNVxkZjSAgOJjy7qN8tZ2Zw')

        this.scrollChatWindowToBottom()

        /*this.socket.emit('chat message', this.message, this.sourceid);
        this.message = '';*/
      }
    },
    chatTimer() {
      this.loadingTimer = setTimeout(() => {
        this.messages.push({class: 'is-toast', text: this.getTextFromKey('long_answer')})
      }, 30000)
      this.errorTimer = setTimeout(() => {
        this.messages.push({class: 'is-error', text: this.getTextFromKey('error_answer')})

        this.loadingAnswer = false
      }, 70000)
    },
    consentGiven() {
      localStorage.setItem('destibotConsentDate', new Date().toISOString())
      this.hasConsent = true
    },
    scrollChatWindowToBottom() {
      this.$nextTick(() => {
        this.$refs.chatwindow.scrollTop = this.$refs.chatwindow.scrollTop + 20000;
      })
    },
    userUsesSuggestion(suggestion) {
      this.userWriting = suggestion.text
      this.suggestion = suggestion
      this.userSentMessage()
    },
    async userSentMessage() {
      if (this.userWriting === '') return

      this.suggestions = []
      this.loadingAnswer = true
      this.latestWriting = this.userWriting
      this.userWriting = ''
      this.chatTimer()

      this.messages.push({class: 'is-user', text: this.latestWriting})
      this.scrollChatWindowToBottom()

      const {error, gpt_timeout} = await this.request()

      if (gpt_timeout) {
        this.userWriting = ''
        this.loadingAnswer = false
        clearTimeout(this.loadingTimer)
        clearTimeout(this.errorTimer)
        this.$nextTick(() => {
          this.focus++;
        })

        this.messages.push({class: 'is-toast', text: 'Der Destibot wurde für diesen Chat abgeschaltet.'})
      } else {
        this.loadingAnswer = true
        this.scrollChatWindowToBottom()
      }

      if (error) {
        this.messages.push({class: 'is-error', text: this.getTextFromKey('error')})
        clearTimeout(this.loadingTimer)
        clearTimeout(this.errorTimer)
        this.loadingAnswer = false
        this.$nextTick(() => {
          this.focus++;
        })
        return false;
      }
    },
    async request() {
      return fetch(process.env.VUE_APP_API_URL + "/chat/web", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "C1bppjJcBqabmf0ZNKein6EnBFwHLneL7aUT18eL"
        },
        body: JSON.stringify({
          "source_key": this.integration,
          "source_id": this.sourceid,
          "url": location.href,
          "type": "text",
          "message": this.latestWriting,
          "consent": this.consent,
          "consentDate": localStorage.getItem('destibotConsentDate'),
          "suggestion": this.suggestion?.id || null,
        })
      })
          .then(ret => ret.json())
          .then((ret) => {
            this.suggestion = null
            return ret
          })
    },
    async retry() {
      console.log("retry")

      if (this.latestWriting === '') return
      this.loadingAnswer = true

      this.messages.push({class: 'is-user', text: this.latestWriting + " (" + this.getTextFromKey('sent_again') + ")"})
      this.userWriting = ''

      const {gpt_timeout, error} = await this.request()

      if (gpt_timeout) {
        this.userWriting = ''
        clearTimeout(this.loadingTimer)
        clearTimeout(this.errorTimer)
        this.$nextTick(() => {
          this.focus++;
        })
      } else {
        this.loadingAnswer = true
        this.scrollChatWindowToBottom()
      }

      if (error) {
        this.messages.push({class: 'is-error', text: this.getTextFromKey('error')})
        clearTimeout(this.loadingTimer)
        clearTimeout(this.errorTimer)
        this.loadingAnswer = false
        this.$nextTick(() => {
          this.focus++;
        })
        return false;
      }

    },
    markdownToHtmlLink(markdownText) {
      if (!markdownText) {
        return "";
      }

      const utmParams = "utm_source=destibot";

      // Patterns for different Markdown elements
      // eslint-disable-next-line
      const patterns = {
        // eslint-disable-next-line
        headers: /^(#+)<\>s*(.+)/gm,
        bold: /\*\*(.*?)\*\*/g,
        italic: /_(.*?)_/g,
        code: /`(.*?)`/g,
        // eslint-disable-next-line
        image: /!\[([^\[]+)]\(([^)]+)\)/g,
        // eslint-disable-next-line
        link: /\[([^\[]+)]\(([^)]+)\)/g,
        unorderedList: /^\*\s+(.+)/gm,
        orderedList: /^\d+\.\s+(.+)/gm,
        blockquote: /^>\s*(.+)/gm
      };

      // Replace Markdown with HTML
      let html = markdownText
          .replace(patterns.headers, (match, hashes, text) => `<h${hashes.length}>${text}</h${hashes.length}>`)
          .replace(patterns.bold, "<strong>$1</strong>")
          .replace(patterns.italic, "<em>$1</em>")
          .replace(patterns.code, "<code>$1</code>")
          .replace(patterns.image, '<img src="$2" alt="$1" />')
          .replace(patterns.link, (match, text, link) => {
            link = link.includes("?") ? link + "&" + utmParams : link + "?" + utmParams;
            return `<a href="${link}" target="_blank">${text}</a>`;
          })
          .replace(patterns.unorderedList, "<ul><li>$1</li></ul>")
          .replace(patterns.orderedList, "<ol><li>$1</li></ol>")
          .replace(patterns.blockquote, "<blockquote>$1</blockquote>");

      // Handle nested lists
      html = html.replace(/<\/ul>\s*<ul>/g, "")
          .replace(/<\/ol>\s*<ol>/g, "");

      return html;
    }
  }
}
</script>

<style scoped lang="scss">


$primary-color: var(--primary);
$secondary-color: var(--secondary);
$gallery: #f0f0f0;
$white: #fff;
$font-color: var(--font-color);
$gray: #bdbdbd;
$athens-gray: #e8e8e8;
$purple-heart: #ea7171;

$chat-height: 70vh;
$chat-max-width: 420px;
$chat-distance-to-window: 80px;
$chat-padding: 20px;
$header-height: 54px;
$entry-height: 60px;

.a {
  fill: none;
}

.b {
  clip-path: url(#a);
}

.c {
  fill: #041e43;
}

.d {
  fill: #00e0ac;
}

.destibot__root {
  all: initial;

  --primary: #ea7171;
  --secondary: #7ee0d2;
  --font-color: #ffffff;

  font-family: 'Rubik', Helvetica, Arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $font-color;
  margin-top: 60px;

  box-sizing: border-box;
  @import url('https://cdn.codacore.ai/Rubik-Regular.woff');
  z-index: 9999;
  position: fixed;

  input {
    font-family: 'Open Sans', sans-serif;
  }

  strong {
    font-weight: 600;
  }


  .intro {
    display: block;
    margin-bottom: 20px;
  }

  * {
    box-sizing: border-box;
  }
}

.destibot {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 54px calc(100vh - 60px - 54px) 60px;
  grid-template-areas:
    "header"
    "main"
    "footer";

  @media screen and (min-width: 640px) {
    max-width: $chat-max-width;
    right: $chat-distance-to-window;
    top: auto;
    grid-template-rows: $header-height auto auto;
  }

  &.destibot--closed {
    top: auto;
    width: 100%;
    grid-template-rows: $header-height auto auto;
  }

  .destibot__logo {
    position: absolute;
    top: 0;
    right: 10px;
    height: 50px;
    transform: rotate(0);
    transition: ease-out 0.5s;
    padding-top: 8px;
    z-index: -1;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    backdrop-filter: blur(10px) brightness(2);

    svg {
      width: 80px;
    }

    &.destibot__logo--closed {
      opacity: 0;
    }
  }

  &:hover {
    .destibot__logo {
      position: absolute;
      top: -32px;
      transform: rotate(5deg);
    }
  }
}

.destibot__header {
  color: $font-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $primary-color;
  height: $header-height;
  padding: 0 $chat-padding;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.2s ease;

  grid-area: header;

  &:hover {
    filter: grayscale(100%);
  }



  p {
    margin-right: $chat-padding;
  }

  .destibot__logos {
    display: flex;
    justify-content: flex-end;
    gap: 15px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.destibot__close-button {
  fill: $white;

  &.icon-speech {
    width: 20px;
    display: none;

    .destibot--closed & {
      display: block;
    }
  }

  &.icon-close {
    width: 14px;

    .destibot--closed & {
      display: none;
    }
  }
}

.destibot__consent {
  color: black;
  position: absolute;
  width: 100%;
  padding: 20px;
  /* top: $header-height; */
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,0.85);
  border-top: 1px solid black;
  z-index: 4;

  a {
    color: black;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  button {
    background-color: $primary-color;
    color: $font-color;
    border: none;
    box-shadow: none;
    padding: 8px;
    padding-left: 12px;
    padding-right: 10px;
    cursor: pointer;
  }
}

.destibot__message-window {
  // height: calc(100% - (#{$header-height} + #{$entry-height}));
  padding: ($chat-padding * 2) $chat-padding $chat-padding;
  background-color: $white;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  grid-area: main;

  @media screen and (min-width: 640px) {
    height: $chat-height;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .destibot--closed & {
    display: none;
  }
}

.destibot__messages {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: auto;
  // height: 100%;

  li {
    margin-bottom: $chat-padding;

    &.is-ai {
      display: inline-flex;
      align-items: flex-start;
      text-align: left;

      a {
        border: black 1px solid;

        &:visited, &:active, &:link {
          color: var(--font-color);
        }

        color: var(--font-color);
        text-decoration: underline;
        transition: all 0.2s ease-in-out;

        &:hover {
          color: var(--secondary);
          text-decoration: none;
        }
      }
    }

    &.is-toast {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    &.is-user {
      text-align: left;
      display: inline-flex;
      align-self: flex-end;

      &.ignored {
        opacity: 0.5;
      }
    }

    &.is-destilogo {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 60px;
      }
    }

    .is-ai__profile-picture {
      margin-right: 8px;

      width: 40px;
      height: 40px;
      background-color: $primary-color;
      border-radius: 50px;

      .icon-avatar {
        width: 40px;
        height: 40px;
        padding: 10px;
      }

    }
  }
}

.destibot__toast {
  display: inline-block;
  padding: 5px $chat-padding;
  word-break: break-word;
  margin: 0 auto;
  font-size: 14px;
  border-radius: 6px;
  letter-spacing: -0.01em;
  line-height: 1;
  overflow: hidden;
  background-color: $athens-gray;
  margin-right: $chat-padding * 1.5;
  margin-left: $chat-padding * 1.5;
  color: black;

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.destibot__dataprotection {
  color: $gray;
  margin-bottom: 1rem;

  a {
    color: $gray;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.destibot__contact__wrapper {
  background-color: white;
}

.destibot__contact {
  color: $gray;
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 10px;
  padding-right: 20px;
  font-size: 0.8rem;
  cursor: pointer;
}

.destibot__error {
  display: inline-block;
  padding: 5px $chat-padding;
  word-break: break-word;
  margin: 0 auto;
  font-size: 14px;
  border-radius: 6px;
  letter-spacing: -0.01em;
  line-height: 1;
  overflow: hidden;
  background-color: lightcoral;
  color: white;
  margin-right: $chat-padding * 1.5;
  margin-left: $chat-padding * 1.5;

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.destibot__message {
  display: inline-block;
  padding: 12px $chat-padding;
  word-break: break-word;
  margin: 0;
  border-radius: 6px;
  letter-spacing: -0.01em;
  line-height: 1.45;
  overflow: hidden;
  text-align: left;
  white-space: pre-line;
  color: $primary-color;

  .is-ai & {
    background-color: $gallery;
    color: black;
    margin-right: $chat-padding * 1.5;
    white-space: pre-line;
  }

  .is-user & {
    background-color: $primary-color;
    color: $font-color;
    margin-left: $chat-padding * 1.5;
    white-space: pre-line
  }

  a {
    color: $purple-heart;
    word-break: break-all;
    display: inline-block;
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  button {
    background-color: $white;
    font-weight: 300;
    border: 2px solid $purple-heart;
    border-radius: 50px;
    padding: 8px 20px;
    margin: -8px 10px 18px 0;
    transition: background-color 0.2s ease;
    cursor: pointer;

    &:hover {
      background-color: darken($white, 05%);
    }

    &:focus {
      outline: none;
    }
  }

  img {
    max-width: 100%;
  }

  .card {
    background-color: $white;
    text-decoration: none;
    overflow: hidden;
    border-radius: 6px;
    color: black;
    word-break: normal;

    .card-content {
      padding: $chat-padding;
    }

    .card-title {
      margin-top: 0;
    }

    .card-button {
      color: $primary-color;
      text-decoration: underline;
    }
  }

}

.destibot__button {
  background-color: #000000; /* Green */
  border: none;
  border-radius: 6px;
  color: white;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;

  &:active {
    background-color: #333333;
  }
}

.animation {
  &:last-child {
    animation: fadein .25s;
    animation-timing-function: all 200ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
  }
}

.destibot__arrow {
  width: 0;
  height: 0;
  margin-top: 18px;
}

.destibot__arrow--right {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid $primary-color;
}

.destibot__arrow--left {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid $gallery;
}

.destibot__suggestions__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
}

.destibot__suggestions {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 20px;
  flex-wrap: nowrap;
  gap: 10px;
  color: black;
  overflow-x: auto;
  flex: 10 0 auto;
}

.destibot__suggestion {
  background-color: $gallery;
  border-radius: 6px;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  flex-shrink: 0;

  &:hover {
    background-color: darken($gallery, 5%);
  }
}

.destibot__suggestion__title {
  margin-bottom: 5px;
  width: 100%;
  color: black;
  height: 40px;
  text-align: right;
}


.destibot__footer {
  grid-area: footer;
}

.destibot__entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $entry-height;
  padding: 0 $chat-padding;
  border-top: 1px solid $athens-gray;
  background-color: $white;
  position: relative;

  .destibot--closed & {
    display: none;
  }

  .destibot__wa-action {
    width: $entry-height;
    height: $entry-height;
    background-color: #25D366;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &:hover {
      filter: grayscale(100%);
    }
  }
}

.destibot__input {
  max-height: $entry-height;
  width: 80%;
  border: 0;
  font-family: 'Rubik', Helvetica, Arial, sans-serif;

  &:focus {
    outline: none;
  }

  &::-webkit-input-placeholder {
    color: $gray;
  }

  &::-moz-placeholder {
    color: $gray;
  }

  &::-ms-input-placeholder {
    color: $gray;
  }

  &::-moz-placeholder {
    color: $gray;
  }
}

.destibot__submit {
  fill: $primary-color;
  height: $entry-height;
  width: $entry-height;
  transition: all 0.2s ease;
  cursor: pointer;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;

  &.destibot__submit--wa {
    right: $entry-height;
  }

  &:hover {
    filter: grayscale(100%);
    background-color: #b9b9b9;
  }
}

.u-text-highlight {
  color: var(--font-color);
  font-size: 0.7rem;
  border: 1px solid var(--secondary);
  background-color: var(--primary);
  padding: 3px;
  border-radius: 2px;
  display: inline-block;
  transform: translate(0, -1px);
  margin-right: 7px;
}

//Animated Loader
//----------------
.loader {
  margin-bottom: -2px;
  text-align: center;
  opacity: .3;
}

.loader__dot {
  display: inline-block;
  vertical-align: middle;
  width: 6px;
  height: 6px;
  margin: 0 1px;
  background: black;
  border-radius: 50px;
  animation: loader 0.45s infinite alternate;

  &:nth-of-type(2) {
    animation-delay: .15s;
  }

  &:nth-of-type(3) {
    animation-delay: .35s;
  }
}

// KeyFrames
@keyframes loader {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
    margin-top: 10px;
    margin-bottom: 0;
  }
  to {
    opacity: 1;
    margin-top: 0;
    margin-bottom: 10px;
  }
}


</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
