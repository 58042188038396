<template>
  <textarea
      :style="computedStyles"
      v-model="val"
      @focus="resize"
      @keydown.enter.prevent="$emit('enter')"
      ref="textarea"
  ></textarea>
</template>
<script>
export default {
  name: 'TextareaAutosize',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    focus: {
      type: [Number],
      default: 0
    },
    /*
     * Force !important for style properties
     */
    important: {
      type: [Boolean, Array],
      default: false
    }
  },
  data () {
    return {
      // data property for v-model binding with real textarea tag
      val: null,
      height: 25,
      maxHeight: 60
    }
  },
  computed: {
    computedStyles () {
      return {
        resize: !this.isResizeImportant ? 'none' : 'none !important',
        height: this.height + 'px !important',
        fontSize: '16px'
      }
    },
    isResizeImportant () {
      const imp = this.important
      return imp === true || (Array.isArray(imp) && imp.includes('resize'))
    },
    isHeightImportant () {
      const imp = this.important
      return imp === true || (Array.isArray(imp) && imp.includes('height'))
    }
  },
  watch: {
    value (val) {
      this.val = val
    },
    val (val) {
      this.$nextTick(this.resize)
      this.$emit('input', val)
    },
    focus () {
      this.$refs.textarea.focus()
    }
  },
  methods: {
    resize () {
      this.$nextTick(() => {
        let contentHeight = this.$el.scrollHeight

        if (this.maxHeight) {
          if (contentHeight > this.maxHeight) {
            contentHeight = this.maxHeight
            this.maxHeightScroll = true
          } else {
            this.maxHeightScroll = false
          }
        }

        if (contentHeight > 25) {
          this.height = contentHeight
        }
      })

      return this
    }
  },
  created () {
    this.val = this.value
  },
  mounted () {
    this.resize()
  }
}
</script>

<style lang="scss" scoped>

textarea {
  height: 25px;
}

</style>
