<template>
  <div>
    <DestiBotBar v-if="loaded && destibotType === 'bar'" :launch="launch" :sourceid="sourceID" :consent="consent" :integration="integration" />
    <DestiBotDot v-if="loaded && destibotType === 'dot'" :launch="launch" :sourceid="sourceID" :consent="consent" :integration="integration" />
    <DestiBotGrf v-if="loaded && destibotType === 'grf'" :launch="launch" :sourceid="sourceID" :consent="consent" :integration="integration" />

  </div>
</template>

<script>
import DestiBotBar from "@/components/DestiBotBar";
import DestiBotDot from "@/components/DestiBotDot";
import DestiBotGrf from "@/components/DestiBotGrf";

export default {
  name: 'App',
  components: {
    DestiBotBar, DestiBotDot, DestiBotGrf
  },
  data() {
    return {
      destibotType: 'bar',
      sourceID: '',
      integration: '',
      launch: null,
      loaded: false,
      consent: true,
      lang: 'de',
      isDemo: false
    }
  },
  async mounted() {
    this.language = this.getWebsiteLanguage()
    console.log('destibot_lang', this.language)


    let script = null;
    try {
      script = document.querySelector('SCRIPT[data-integration]');
    } catch (e) {
      console.error('DestiBot: No script tag found. Use fallback.')
    }
    if (process.env.NODE_ENV === 'production') {
      this.integration = script?.getAttribute('data-integration');
    } else {
      this.integration = script?.getAttribute('data-integration') || 'web-integration-fallback-55bfef95601';
    }

    this.sourceID = localStorage.getItem('destibotSourceID');

    if (!this.sourceID) {
      // Schema: [zweck]-[rand]-[integration]-[timestamp]
      if (location.href.includes('demo.codacore.ai')) {
        this.sourceID = "demo-" + (Math.random().toString().replaceAll('.', '')) + "-" + this.integration + "-" + Date.now()
        this.isDemo = true
      } else {
        this.sourceID = "web-integration-" + (Math.random().toString().replaceAll('.', '')) + "-" + this.integration + "-" + Date.now()
      }
      localStorage.setItem('destibotSourceID', this.sourceID)
    }

    const integration_launch = await fetch(process.env.VUE_APP_API_URL + "/chat/web/launch" + (this.isDemo ? "?type=demo" : ""), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "U82idgUDgg7gGR3y0bXkx4BAFzmitneA882TUuxn"
      },
      body: JSON.stringify({
        "source_key": this.integration,
        "source_id": this.sourceID,
        "url": location.href,
        "lang": this.language
      })
    }).then(ret => ret.json())

    if (!integration_launch.bot) {
      // Bot not active
      return false;
    }

    let destibotlink = "";
    if (this.isDemo) {
      destibotlink += "https://destibot.ai/?utm_campaign=destibot-demo&utm_source=webclient&utm_medium=" + integration_launch.config?.shortform
    } else {
      destibotlink = integration_launch.config?.destibotlink || 'https://destibot.ai/?utm_campaign=destibot-integration&utm_source=webclient&utm_medium=unclear'
    }

    this.destibotType = integration_launch.config.botType || 'dot'

    this.launch = {
      "lang": this.language,
      "welcome_message": integration_launch.welcome_message || "Hallo, ich bin der DestiBot. Wie kann ich dir helfen?",
      "chats": integration_launch.chats || [],
      "examples": integration_launch.examples || ['Über was kannst du auskunft geben?'],
      "frontend": {
        "primary": integration_launch.frontend.primary,
        "secondary": integration_launch.frontend.secondary,
        "font": integration_launch.frontend.font
      },
      "texts": integration_launch.texts,
      "config": {
        "brand": integration_launch.config.brand,
        "newChip": integration_launch.config.newChip,
        "whatsapp": integration_launch.config.whatsapp,
        "destibotlink": destibotlink,
        "icon_image": integration_launch.config.icon_image,
        "logo_image": integration_launch.config.logo_image,
        "background_image": integration_launch.config.background_image,
        "contact": integration_launch.config.botType === 'dot' ? true : false, // TODO: dynamisch machen
        "botType": integration_launch.config.botType || "bar",
        "botCharacterImage": integration_launch.config.botCharacterImage || null,
        "autoOpen": integration_launch.config.autoOpen || false
      },
      "suggestions": integration_launch.suggestions
    }
    this.loaded = true
  },
  methods: {
    getWebsiteLanguage() {
      // Try to get the language from the website
      let lang = document.documentElement.lang;

      if (lang && lang.trim() !== "") {
        return lang.slice(0,2);
      } else {
        // If lang attribute is not set, use the browser's default language
        return (navigator.language || navigator.userLanguage).slice(0,2);
      }
    }
  }
}
</script>

<style lang="scss">
#destibot {
  all: initial;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

$primary-color: var(--primary);
.is-ai {
  a {
    color: black;
    text-decoration: underline;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: var(--secondary);
      text-decoration: none;
    }
  }
}

</style>
