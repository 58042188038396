<template>
  <div class="destibot__root" ref="destibot">
    <div class="destibot destibot__type--dot" :class="{'destibot--closed':closed}">
      <div class="destibot__header" @click="toggleChat">
        <div>{{ getTextFromKey('hast_du_eine_frage') }}</div>
        <div class="destibot__logos">
          <svg
              v-if="launch.config.whatsapp !== false"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
          >
            <path
                fill="white"
                d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"
            />
          </svg>
          <svg class="destibot__close-button icon-speech" viewBox="0 0 32 32">
            <path
                d="M21.795 5.333h-11.413c-0.038 0-0.077 0-0.114 0l-0.134 0.011v2.796c0.143-0.006 0.273-0.009 0.385-0.009h11.277c0.070 0 7.074 0.213 7.074 7.695 0 5.179-2.956 7.695-9.036 7.695h-3.792c-0.691 0-1.12 0.526-1.38 1.159l-1.387 3.093-1.625 3.77 0.245 0.453h2.56l2.538-5.678h2.837c7.633 0 11.84-3.727 11.84-10.494 0.001-8.564-7.313-10.492-9.875-10.492z"
            />
            <path
                d="M10.912 24.259c-0.242-0.442-0.703-0.737-1.234-0.737-0 0-0 0-0 0h-0.56c-0.599-0.011-1.171-0.108-1.71-0.28l0.042 0.012c-1.82-0.559-4.427-2.26-4.427-7.424 0-6.683 5.024-7.597 7.109-7.686v-2.8c-2.042 0.095-9.91 1.067-9.91 10.483 0 4.832 1.961 7.367 3.606 8.64 1.38 1.067 3.109 1.744 4.991 1.843l0.033 0.019 2.805 5.211 1.41-3.273z"
            />
          </svg>
          <svg class="destibot__close-button icon-close" viewBox="0 0 32 32">
            <path d="M2.624 8.297l2.963-2.963 23.704 23.704-2.963 2.963-23.704-23.704z" />
            <path d="M2.624 29.037l23.704-23.704 2.963 2.963-23.704 23.704-2.963-2.963z" />
          </svg>
        </div>
      </div>
      <div class="destibot__message-window" ref="chatwindow">
        <ul class="destibot__messages" ref="messages">

          <div class="destibot__dataprotection"><a
              href="https://destibot.ai/datenschutz-bot?utm_campaign=destibot-integration-dataprotection&utm_source=webclient&utm_medium="
              target="_blank"
          >{{ getTextFromKey('data_protection') }}</a>
          </div>

          <template v-for="(message, ind) in messages">

            <li v-if="message.class === 'is-ai'" :key="ind" class="is-ai animation">
              <div class="is-ai__profile-picture">
                <svg class="icon-avatar" alt="chatbot integration" id="Capricorn-ai" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170 200">
                  <path fill="#3c3c3c" stroke-width="0" d="M166.84,20.83c-21.14-1.17-47.5,14-43,59.17h-33.84C90,31.7,111.33-2.36,170,.13l-3.16,20.7ZM3.16,20.83c21.14-1.17,47.5,14,43,59.17h33.84C80,31.7,58.67-2.36,0,.13l3.16,20.7Z"/>
                  <path fill="#c60219" stroke-width="0" d="M136.43,80c0,47.85-36,86.83-85.17,86.83l-5.06,33.17h5.06c64.81,0,115.58-54.25,115.58-120h-30.41Z"/>
                </svg>
              </div>
              <span class="destibot__arrow destibot__arrow--left"></span>
              <p class="destibot__message" v-html="markdownToHtmlLink(message.text)"></p>
            </li>

            <li v-if="message.class === 'is-toast'" :key="ind" class="is-toast animation">
              <p class="destibot__toast">{{ message.text }}</p>
            </li>

            <li v-if="message.class === 'is-error'" :key="ind" class="is-error animation">
              <p class="destibot__error">{{ message.text }}</p>
              <button class="destibot__button" @click.prevent="retry">{{ getTextFromKey('try_again') }}</button>
            </li>


            <li
                v-if="message.class === 'is-user'"
                :key="ind"
                class="is-user animation"
                :class="{'hide': message.ignored }"
            >
              <p class="destibot__message">
                {{ message.text }}
              </p>
              <span class="destibot__arrow destibot__arrow--right"></span>
            </li>

          </template>


          <li v-if="loadingAnswer" class="is-ai animation">
            <div class="is-ai__profile-picture">
              <svg class="icon-avatar" alt="chatbot integration" id="Capricorn-ai" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170 200">
                <path fill="#3c3c3c" stroke-width="0" d="M166.84,20.83c-21.14-1.17-47.5,14-43,59.17h-33.84C90,31.7,111.33-2.36,170,.13l-3.16,20.7ZM3.16,20.83c21.14-1.17,47.5,14,43,59.17h33.84C80,31.7,58.67-2.36,0,.13l3.16,20.7Z"/>
                <path fill="#c60219" stroke-width="0" d="M136.43,80c0,47.85-36,86.83-85.17,86.83l-5.06,33.17h5.06c64.81,0,115.58-54.25,115.58-120h-30.41Z"/>
              </svg>
            </div>
            <span class="destibot__arrow destibot__arrow--left"></span>
            <div class="destibot__message">
              <span class="loader"><span class="loader__dot"></span><span class="loader__dot"></span><span
                  class="loader__dot"
              ></span></span>
            </div>
          </li>

          <div v-if="suggestions.length >= 1 && !enterEmail" class="destibot__suggestions__wrapper">
            <div class="destibot__suggestion__title" v-if="messages.length <= 1">
              {{ getTextFromKey('suggestions') }}
            </div>
            <div class="destibot__suggestions">
              <div v-for="(suggestion, ind) in suggestions" :key="ind" class="destibot__suggestion" @click="userUsesSuggestion(suggestion)">
                {{ suggestion.label }}
              </div>
            </div>
          </div>
        </ul>
      </div>
      <div class="destibot__footer">
        <div v-if="!closed && hasConsent" class="destibot__contact__wrapper">
          <sticky-mail v-if="enterEmail" @close="enterEmail = false" :sourceid="sourceid" :integration="integration" :launch="launch" :socket="socket" />
          <div v-else-if="launch.config.contact" class="destibot__contact" @click="enterEmail = true">
            <span><u>{{ getTextFromKey('contact_prompt') }}</u></span>
          </div>
        </div>
        <div class="destibot__entry destibot--closed">
          <TextareaAutosize
              v-model="userWriting"
              :disabled="loadingAnswer || !hasConsent"
              class="destibot__input"
              :placeholder="loadingAnswer ? getTextFromKey('wait') : getTextFromKey('write')"
              @enter="userSentMessage"
              :focus="focus"
          ></TextareaAutosize>
          <!--<input type="text" v-model="userWriting" ref="messageenter" :disabled="loadingAnswer || !hasConsent" class="destibot__input"
                 :placeholder="loadingAnswer ? 'warten...' : 'Schreibe eine Nachricht...'"
                 @keydown.enter.prevent="userSentMessage"/>ä--->
          <div
              class="destibot__submit"
              :class="{'destibot__submit--wa':launch.config.whatsapp !== false}"
              @click="userSentMessage"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M0.31,9.43a0.5,0.5,0,0,0,0,.93l8.3,3.23L23.15,0Z" />
              <path d="M9,14.6H9V21.4a0.5,0.5,0,0,0,.93.25L13.22,16l6,3.32A0.5,0.5,0,0,0,20,19l4-18.4Z" />
            </svg>
          </div>
          <a
              v-if="launch.config.whatsapp !== false"
              :href="launch.config.whatsapp"
              target="_blank"
              class="destibot__wa-action"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                  fill="white"
                  d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"
              />
            </svg>
          </a>
        </div>
      </div>
      <div v-if="!closed & !hasConsent" class="destibot__consent">
        <div>
          Ich akzeptiere die <a
            href="https://destibot.ai/datenschutz-bot?utm_campaign=destibot-integration-dataprotection&utm_source=webclient&utm_medium="
            target="_blank"
        >{{ getTextFromKey('data_protection') }}</a>. <br>
          {{ getTextFromKey('consent') }}
          <br>
          <br>
          <button @click="consentGiven">{{ getTextFromKey('consent_button') }}</button>
        </div>
      </div>
    </div>
    <div class="destibot__charakter grf" @click="toggleChat">
      <div v-if="launch.config.brand && closed" class="destibot__callout">
        {{ getTextFromKey('hast_du_eine_frage') }}
      </div>
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 342.98 342.98">
        <path class="cls-3" fill="#3c3c3c" d="M258.33,106.32c-21.14-1.17-47.5,14-43,59.17h-33.84c0-48.3,21.33-82.36,80-79.87l-3.16,20.7ZM94.65,106.32c21.14-1.17,47.5,14,43,59.17h33.84c0-48.3-21.33-82.36-80-79.87l3.16,20.7Z"/>
        <path class="cls-2" fill="#c52126" d="M227.92,165.49c0,47.85-36,86.83-85.17,86.83l-5.06,33.17h5.06c64.81,0,115.58-54.25,115.58-120h-30.41Z"/>
        <g id="chat-bubble-oval--messages-message-bubble-chat-oval">
          <path id="Ellipse_1144" data-name="Ellipse 1144" stroke="#000" fill="none" stroke-width="10px"  class="cls-1" d="M98.35,321.1c22.08,10.81,46.9,16.88,73.13,16.88,91.95,0,166.49-74.54,166.49-166.49S263.44,5,171.49,5,5,79.54,5,171.49c0,34.13,10.27,65.87,27.89,92.28M98.35,321.1l-93.35,16.88,27.89-74.21M98.35,321.1l.14-.02M32.89,263.77l.03-.07"/>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import TextareaAutosize from "@/components/TextareaAutosize.vue";
import StickyMail from "@/components/StickyMail.vue";
import {io} from "socket.io-client";

export default {
  name: 'DestiBotDot',
  components: {StickyMail, TextareaAutosize},
  props: {
    sourceid: String,
    integration: String,
    launch: Object,
    consent: Boolean
  },
  data() {
    return {
      messages: [],
      closed: true,
      wasAutoOpened: false,
      loadingAnswer: false,
      userWriting: '',
      latestWriting: '',
      supabase: null,
      loadingTimer: null,
      errorTimer: null,
      hasConsent: false,
      focus: 0,
      enterEmail: false,
      suggestion: null,
      suggestions: [],
      socket: null
    }
  },
  async mounted() {
    // this.supabase = createClient('https://wdmffaamldunlqudzjcd.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndkbWZmYWFtbGR1bmxxdWR6amNkIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Nzk4NTg4NTIsImV4cCI6MTk5NTQzNDg1Mn0.CsBgFwo0bzc56x_wPY8cQNVxkZjSAgOJjy7qN8tZ2Zw')


    this.$refs.destibot.style.setProperty('--primary', this.launch.frontend.primary)
    this.$refs.destibot.style.setProperty('--secondary', this.launch.frontend.secondary)
    this.$refs.destibot.style.setProperty('--font-color', this.launch.frontend.font)

    this.messages.push({class: 'is-ai', text: this.launch.welcome_message});
    this.suggestions = this.launch.suggestions || []

    /*window.addEventListener('scroll', () => {
      if (window.scrollY > 150 && !this.wasAutoOpened) {
        this.closed = false
        this.wasAutoOpened = true
      }

      if (window.scrollY > 550) {
        this.closed = true
      }
    });*/

    // TODO Check consent date
    if (localStorage.getItem('destibotConsentDate') && this.isDateYoungerThanSixMonths(localStorage.getItem('destibotConsentDate'))) {
      this.hasConsent = true
    }

    if (this.launch.chats.length !== 0) {
      this.launch.chats.forEach(itm => {
        this.messages.push({class: itm.sender, text: itm.message, ignored: itm.ignored});
      })
    }

    /*if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local') {
      await this.toggleChat()
    }*/
  },
  methods: {
    isDateYoungerThanSixMonths(date) {
      const sixMonthsAgo = new Date()
      sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6)
      return new Date(date) > sixMonthsAgo
    },
    getTextFromKey(key) {
      const textObj = this.launch.texts.find(itm => itm.key === key)
      return textObj[this.launch.language] || textObj['de']
    },
    async toggleChat() {
      this.closed = !this.closed
      if (this.closed === false) {
        // window is opened

        console.log(process.env.VUE_APP_WS_URL)
        this.socket = io(process.env.VUE_APP_WS_URL);
        this.socket.emit('join', {
          "source_key": this.integration || "web-integration-fallback-55bfef95601",
          "source_id": this.sourceid,
          "url": location.href,
          "lang": "de"
        });

        this.socket.on('chat message', (msg) => {
          console.log('received: ' + msg);
          if (msg) {
            this.messages.push({class: 'is-ai', text: msg})
            this.scrollChatWindowToBottom()
            clearTimeout(this.loadingTimer)
            clearTimeout(this.errorTimer)
            this.loadingAnswer = false
            this.$nextTick(() => {
              this.focus++;
            })
          }

        });

        this.socket.on('email popup', (popup) => {
          console.log('email popup: ' + popup);
          if (popup) {
            this.enterEmail = true
          }
        });

        this.socket.on('suggestions', (msg) => {
          console.log('suggestion received: ' + msg);
          if (msg) {
            this.suggestions = []
            const sugg = JSON.parse(msg)
            sugg.forEach(itm => {
              this.suggestions.push({
                label: itm,
                text: itm,
                id: "suggestion-" + Math.random().toString(36).substring(7)
              })
            })
          }
        });

        // this.supabase = createClient('https://wdmffaamldunlqudzjcd.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndkbWZmYWFtbGR1bmxxdWR6amNkIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Nzk4NTg4NTIsImV4cCI6MTk5NTQzNDg1Mn0.CsBgFwo0bzc56x_wPY8cQNVxkZjSAgOJjy7qN8tZ2Zw')

        this.scrollChatWindowToBottom()
      } else {
        // window is closed
        this.socket.disconnect()
        this.socket = null
      }
    },
    chatTimer() {
      this.loadingTimer = setTimeout(() => {
        this.messages.push({class: 'is-toast', text: this.getTextFromKey('long_answer')})
      }, 30000)
      this.errorTimer = setTimeout(() => {
        this.messages.push({class: 'is-error', text: this.getTextFromKey('error_answer')})

        this.loadingAnswer = false
      }, 70000)
    },
    consentGiven() {
      localStorage.setItem('destibotConsentDate', new Date().toISOString())
      this.hasConsent = true
    },
    scrollChatWindowToBottom() {
      this.$nextTick(() => {
        this.$refs.chatwindow.scrollTop = this.$refs.chatwindow.scrollTop + 20000;
      })
    },
    userUsesSuggestion(suggestion) {
      this.userWriting = suggestion.text
      this.suggestion = suggestion
      this.userSentMessage()
    },
    async userSentMessage() {
      if (this.userWriting === '') return

      this.suggestions = []
      this.loadingAnswer = true
      this.latestWriting = this.userWriting
      this.userWriting = ''
      this.chatTimer()

      this.messages.push({class: 'is-user', text: this.latestWriting})
      this.scrollChatWindowToBottom()

      const {error, gpt_timeout} = await this.request()

      if (gpt_timeout) {
        this.userWriting = ''
        clearTimeout(this.loadingTimer)
        clearTimeout(this.errorTimer)
        this.$nextTick(() => {
          this.focus++;
        })
      } else {
        this.loadingAnswer = true
        this.scrollChatWindowToBottom()
      }

      if (error) {
        this.messages.push({class: 'is-error', text: this.getTextFromKey('error')})
        clearTimeout(this.loadingTimer)
        clearTimeout(this.errorTimer)
        this.loadingAnswer = false
        this.$nextTick(() => {
          this.focus++;
        })
        return false;
      }
    },
    async request() {
      return fetch(process.env.VUE_APP_API_URL + "/chat/web", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "C1bppjJcBqabmf0ZNKein6EnBFwHLneL7aUT18eL"
        },
        body: JSON.stringify({
          "source_key": this.integration,
          "source_id": this.sourceid,
          "url": location.href,
          "type": "text",
          "message": this.latestWriting,
          "consent": this.consent,
          "consentDate": localStorage.getItem('destibotConsentDate'),
          "suggestion": this.suggestion?.id || null,
        })
      })
          .then(ret => ret.json())
          .then((ret) => {
            this.suggestion = null
            return ret
          })
    },
    async retry() {
      console.log("retry")

      if (this.latestWriting === '') return
      this.loadingAnswer = true

      this.messages.push({class: 'is-user', text: this.latestWriting + " (" + this.getTextFromKey('sent_again') + ")"})
      this.userWriting = ''

      const {gpt_timeout, error} = await this.request()

      if (gpt_timeout) {
        this.userWriting = ''
        clearTimeout(this.loadingTimer)
        clearTimeout(this.errorTimer)
        this.$nextTick(() => {
          this.focus++;
        })
      } else {
        this.loadingAnswer = true
        this.scrollChatWindowToBottom()
      }

      if (error) {
        this.messages.push({class: 'is-error', text: this.getTextFromKey('error')})
        clearTimeout(this.loadingTimer)
        clearTimeout(this.errorTimer)
        this.loadingAnswer = false
        this.$nextTick(() => {
          this.focus++;
        })
        return false;
      }

    },
    markdownToHtmlLink(markdownLink) {
      if (!markdownLink) {
        return "";
      }

      const utmParams = "utm_source=destibot";

      // Patterns for different Markdown elements
      // eslint-disable-next-line
      const patterns = {
        // eslint-disable-next-line
        headers: /^(#+)<\>s*(.+)/gm,
        bold: /\*\*(.*?)\*\*/g,
        italic: /_(.*?)_/g,
        code: /`(.*?)`/g,
        // eslint-disable-next-line
        image: /!\[([^\[]+)]\(([^)]+)\)/g,
        // eslint-disable-next-line
        link: /\[([^\[]+)]\(([^)]+)\)/g,
        unorderedList: /^\*\s+(.+)/gm,
        orderedList: /^\d+\.\s+(.+)/gm,
        blockquote: /^>\s*(.+)/gm
      };

      // Replace Markdown with HTML
      let html = markdownLink
          .replace(patterns.headers, (match, hashes, text) => `<h${hashes.length}>${text}</h${hashes.length}>`)
          .replace(patterns.bold, "<strong>$1</strong>")
          .replace(patterns.italic, "<em>$1</em>")
          .replace(patterns.code, "<code>$1</code>")
          .replace(patterns.image, '<img src="$2" alt="$1" />')
          .replace(patterns.link, (match, text, link) => {
            link = link.includes("?") ? link + "&" + utmParams : link + "?" + utmParams;
            return `<a href="${link}" target="_blank">${text}</a>`;
          })
          .replace(patterns.unorderedList, "<ul><li>$1</li></ul>")
          .replace(patterns.orderedList, "<ol><li>$1</li></ol>")
          .replace(patterns.blockquote, "<blockquote>$1</blockquote>");

      // Handle nested lists
      html = html.replace(/<\/ul>\s*<ul>/g, "")
          .replace(/<\/ol>\s*<ol>/g, "");

      return html;
    }
  }
}
</script>

<style scoped lang="scss">


$primary-color: var(--primary);
$secondary-color: var(--secondary);
$gallery: #f0f0f0;
$white: #fff;
$font-color: var(--font-color);
$gray: #bdbdbd;
$athens-gray: #e8e8e8;
$purple-heart: #ea7171;

$chat-height: 50vh;
$chat-max-width: 420px;
$chat-distance-to-window: 30px;
$chat-padding: 20px;
$dot-size: 62px;
$entry-height: 60px;

.a {
  fill: none;
}

.b {
  clip-path: url(#a);
}

.c {
  fill: #b9b9b9;
  // fill: #041e43;
}

.d {
  // fill: #00e0ac;
  fill: #B9B9B9;
}

.destibot__root {
  all: initial;

  --primary: #ea7171;
  --secondary: #7ee0d2;
  --font-color: #ffffff;

  font-family: 'Rubik', Helvetica, Arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $font-color;
  margin-top: 60px;

  box-sizing: border-box;
  @import url('https://cdn.codacore.ai/Rubik-Regular.woff');
  z-index: 9999;
  position: fixed;

  input {
    font-family: 'Open Sans', sans-serif;
  }

  strong {
    font-weight: 600;
  }


  .intro {
    display: block;
    margin-bottom: 20px;
  }

  * {
    box-sizing: border-box;
  }
}

.destibot {
  position: fixed;
  right: 0;
  bottom: calc($chat-distance-to-window + 75px);
  top: 0;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;

  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 1;
  transform: translateY(0px);

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: $dot-size auto auto auto;
  grid-template-areas:
    "header"
    "main"
    "footer";

  @media screen and (min-width: 640px) {
    max-width: $chat-max-width;
    right: $chat-distance-to-window;
    bottom: calc($chat-distance-to-window + 75px);
    top: auto;
  }

  &.destibot--closed {
    top: auto;
    opacity: 0;
    transform: translateY(20px);
  }

  .destibot__logo {
    position: absolute;
    top: 0;
    right: 10px;
    height: 50px;
    transform: rotate(0);
    transition: ease-out 0.5s;
    padding-top: 8px;
    z-index: -1;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    backdrop-filter: blur(10px) brightness(1);

    svg {
      width: 80px;
    }


    &.destibot__logo--closed {
      opacity: 0;
    }
  }

  &:hover {
    .destibot__logo {
      position: absolute;
      top: -32px;
      transform: rotate(5deg);
    }
  }
}

.destibot__header {
  background-color: $primary-color;
  grid-area: header;

  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .destibot--closed & {
    display: none;
  }

  .destibot__logos {
    display: flex;
    justify-content: flex-end;
    gap: 15px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.destibot__charakter {
  height: $dot-size;
  width: $dot-size;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  position: fixed;
  bottom: $chat-distance-to-window;
  right: $chat-distance-to-window;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  padding: 16px;
  background: white;
  border-radius: 100px;

  .destibot__callout {
    position: absolute;
    top: -100%;
    right: 5px;
    transform: rotate(0);
    transition: ease-out 0.5s;
    padding: 8px;
    z-index: -5;
    border-radius: 4px;
    backdrop-filter: blur(10px) brightness(0.8);
    visibility: hidden;
    opacity: 0;


    min-width: 120px;
    font-weight: bold;
    text-align: right;

    &.destibot__logo--closed {
      opacity: 0;
    }
  }

  @media screen and (max-width: 768px) {
    &.grf {
      height: 56px;
      width: 56px;
      bottom: 23px;
      right: 50%;
      transform: translateX(50%);
    }

    .destibot__callout {

      right: 50%;
      transform: rotate(0) translate(50%, 0%);
      text-align: center;

      &.destibot__logo--closed {
        opacity: 0;
      }
    }
  }


  &:hover {
    .destibot__callout {
      visibility: visible;
      opacity: 1;
    }
  }

  .destibot__logos {
    display: flex;
    justify-content: flex-end;
    gap: 15px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .destibot__character__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: rotate(5deg);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50px;
    }
  }
}

.destibot__close-button {
  fill: $white;

  &.icon-speech {
    width: 20px;
    display: none;

    .destibot--closed & {
      display: block;
    }
  }

  &.icon-close {
    width: 14px;

    .destibot--closed & {
      display: none;
    }
  }
}

.destibot__consent {
  color: black;
  position: absolute;
  width: 100%;
  padding: 20px;
  /* top: $dot-size; */
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.85);
  border-top: 1px solid black;
  z-index: 4;

  a {
    color: black;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  button {
    background-color: $primary-color;
    color: $font-color;
    border: none;
    box-shadow: none;
    padding: 8px;
    padding-left: 12px;
    padding-right: 10px;
    cursor: pointer;
  }
}

.destibot__message-window {
  // height: calc(100% - (#{$dot-size} + #{$entry-height}));
  padding: ($chat-padding * 2) $chat-padding $chat-padding;
  background-color: $white;

  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  grid-area: main;

  @media screen and (min-width: 640px) {
    height: $chat-height;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .destibot--closed & {
    display: none;
  }
}

.destibot__messages {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: auto;
  // height: 100%;

  li {
    margin-bottom: $chat-padding;

    &.is-ai {
      display: inline-flex;
      align-items: flex-start;
      text-align: left;

      a {
        border: black 1px solid;

        color: black;
        text-decoration: underline;
        transition: all 0.2s ease-in-out;

        &:hover {
          color: var(--secondary);
          text-decoration: none;
        }
      }
    }

    &.is-toast {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    &.is-user {
      text-align: left;
      display: inline-flex;
      align-self: flex-end;

      &.ignored {
        opacity: 0.5;
      }
    }

    &.is-destilogo {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 60px;
      }
    }

    .is-ai__profile-picture {
      margin-right: 8px;
      width: 40px;
      height: 40px;
      display: block;
      border-radius: 50px;
      background-color: white;
      border: 1px solid black;

      .icon-avatar {
        width: 40px;
        height: 100%;
        transform: scale(0.65);
        display: block
      }

      .destibot__type--dot & {
        position: relative;
        width: 40px;

        .icon-avatar {
          top: 0;
          left: 0;
          transform: scale(0.65);
        }
      }
    }
  }
}

.destibot__toast {
  display: inline-block;
  padding: 5px $chat-padding;
  word-break: break-word;
  margin: 0 auto;
  font-size: 14px;
  border-radius: 6px;
  letter-spacing: -0.01em;
  line-height: 1;
  overflow: hidden;
  background-color: $athens-gray;
  margin-right: $chat-padding * 1.5;
  margin-left: $chat-padding * 1.5;
  color: black;

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.destibot__dataprotection {
  color: $gray;
  margin-bottom: 1rem;

  a {
    color: $gray;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.destibot__contact__wrapper {
  background-color: white;
}

.destibot__contact {
  color: $gray;
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 10px;
  padding-right: 20px;
  font-size: 0.8rem;
  cursor: pointer;
}

.destibot__error {
  display: inline-block;
  padding: 5px $chat-padding;
  word-break: break-word;
  margin: 0 auto;
  font-size: 14px;
  border-radius: 6px;
  letter-spacing: -0.01em;
  line-height: 1;
  overflow: hidden;
  background-color: lightcoral;
  color: white;
  margin-right: $chat-padding * 1.5;
  margin-left: $chat-padding * 1.5;

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.destibot__message {
  display: inline-block;
  padding: 12px $chat-padding;
  word-break: break-word;
  margin: 0;
  border-radius: 6px;
  letter-spacing: -0.01em;
  line-height: 1.45;
  overflow: hidden;
  text-align: left;
  white-space: pre-line;
  color: $primary-color;

  .is-ai & {
    background-color: $gallery;
    color: black;
    margin-right: $chat-padding * 1.5;
    white-space: pre-line;
  }

  .is-user & {
    background-color: $primary-color;
    color: $font-color;
    margin-left: $chat-padding * 1.5;
    white-space: pre-line
  }

  a {
    color: $purple-heart;
    word-break: break-all;
    display: inline-block;
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  button {
    background-color: $white;
    font-weight: 300;
    border: 2px solid $purple-heart;
    border-radius: 50px;
    padding: 8px 20px;
    margin: -8px 10px 18px 0;
    transition: background-color 0.2s ease;
    cursor: pointer;

    &:hover {
      background-color: darken($white, 05%);
    }

    &:focus {
      outline: none;
    }
  }

  img {
    max-width: 100%;
  }

  .card {
    background-color: $white;
    text-decoration: none;
    overflow: hidden;
    border-radius: 6px;
    color: black;
    word-break: normal;

    .card-content {
      padding: $chat-padding;
    }

    .card-title {
      margin-top: 0;
    }

    .card-button {
      color: $primary-color;
      text-decoration: underline;
    }
  }

}

.destibot__button {
  background-color: #000000; /* Green */
  border: none;
  border-radius: 6px;
  color: white;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;

  &:active {
    background-color: #333333;
  }
}

.animation {
  &:last-child {
    animation: fadein .25s;
    animation-timing-function: all 200ms cubic-bezier(0.550, 0.055, 0.675, 0.190);
  }
}

.destibot__arrow {
  width: 0;
  height: 0;
  margin-top: 18px;
}

.destibot__arrow--right {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid $primary-color;
}

.destibot__arrow--left {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid $gallery;
}

.destibot__suggestions__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
}

.destibot__suggestions {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 20px;
  flex-wrap: nowrap;
  gap: 10px;
  color: black;
  overflow-x: auto;
  flex: 10 0 auto;
}

.destibot__suggestion {
  background-color: $gallery;
  border-radius: 6px;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  flex-shrink: 0;

  &:hover {
    background-color: darken($gallery, 5%);
  }
}

.destibot__suggestion__title {
  margin-bottom: 5px;
  width: 100%;
  color: black;
  height: 40px;
  text-align: right;
}


.destibot__footer {
  grid-area: footer;
}

.destibot__entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $entry-height;
  padding: 0 $chat-padding;
  border-top: 1px solid $athens-gray;
  background-color: $white;
  position: relative;

  .destibot--closed & {
    display: none;
  }

  .destibot__wa-action {
    width: $entry-height;
    height: $entry-height;
    background-color: #25D366;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &:hover {
      filter: brightness(120%);
    }
  }
}

.destibot__input {
  max-height: $entry-height;
  width: 80%;
  border: 0;
  font-family: 'Rubik', Helvetica, Arial, sans-serif;

  &:focus {
    outline: none;
  }

  &::-webkit-input-placeholder {
    color: $gray;
  }

  &::-moz-placeholder {
    color: $gray;
  }

  &::-ms-input-placeholder {
    color: $gray;
  }

  &::-moz-placeholder {
    color: $gray;
  }
}

.destibot__submit {
  fill: $primary-color;
  height: $entry-height;
  width: $entry-height;
  transition: all 0.2s ease;
  cursor: pointer;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;

  &.destibot__submit--wa {
    right: $entry-height;
  }

  &:hover {
    filter: brightness(120%);
    background-color: #b9b9b9;
  }
}

.u-text-highlight {
  color: #050400;
  font-size: 0.7rem;
  background-color: #ffd60b;
  padding: 3px;
  border-radius: 2px;
  display: inline-block;
  transform: translate(0, -1px);
  margin-right: 7px;
}

//Animated Loader
//----------------
.loader {
  margin-bottom: -2px;
  text-align: center;
  opacity: .3;
}

.loader__dot {
  display: inline-block;
  vertical-align: middle;
  width: 6px;
  height: 6px;
  margin: 0 1px;
  background: black;
  border-radius: 50px;
  animation: loader 0.45s infinite alternate;

  &:nth-of-type(2) {
    animation-delay: .15s;
  }

  &:nth-of-type(3) {
    animation-delay: .35s;
  }
}

// KeyFrames
@keyframes loader {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
    margin-top: 10px;
    margin-bottom: 0;
  }
  to {
    opacity: 1;
    margin-top: 0;
    margin-bottom: 10px;
  }
}


</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
